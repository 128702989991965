
import hotkeys from "hotkeys-js";
import { Component, Vue, Ref } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { Debounce } from "@/utils/vue-helpers";
import type { indexSpotlightPayload, SpotlightItem } from "@/store/modules/spotlight.store";

@Component({
    components: {
        WorkStatusTag: () => import("@/components/dossier/work-status-tag.vue"),
    },
})
export default class Spotlight extends Vue {
    @Getter("auth/me") me!: CurrentAccount;

    @Action("spotlight/index") indexSpotlight!: (payload: indexSpotlightPayload) => Promise<SpotlightItem[]>;
    @Action("setOfficeId") setOfficeId!: (officeId: number) => void;

    @Mutation("activity/SET_VIEWING") setViewingActivity!: (payload: null | Activity) => void;
    @Mutation("property/SET_VIEWING") setViewingProperty!: (payload: null) => void;

    @Ref() searchInput!: HTMLInputElement;

    open = false;

    selectedIndex = 0;

    search = "";

    searching = false;

    results: SpotlightItem[] = [];

    mounted() {
        hotkeys.unbind("cmd+k,ctrl+k", this.openSpotlight);
        hotkeys("cmd+k,ctrl+k", this.openSpotlight);
    }

    @Debounce(500)
    async handleSearch() {
        this.searching = true;
        this.results = await this.indexSpotlight({
            q: {
                and: {
                    or: {
                        office_id: this.me.offices,
                    },
                    and: {
                        or: {
                            matchable: [`like^1:${this.search}*`, `match^1:${this.search}`],
                        },
                    },
                },
            },
        });
        this.searching = false;
    }

    openSpotlight(event?: KeyboardEvent) {
        if (event) {
            event.preventDefault();
        }

        this.open = !this.open;

        this.$nextTick(() => {
            if (this.searchInput) {
                this.searchInput.focus();
            }
        });
    }

    handleKey(e: KeyboardEvent) {
        if (e.key === "ArrowDown") {
            if (this.selectedIndex === this.results.length - 1) {
                this.selectedIndex = 0;
            } else {
                this.selectedIndex += 1;
            }
        }
        if (e.key === "ArrowUp") {
            if (this.selectedIndex === 0) {
                this.selectedIndex = this.results.length - 1;
            } else {
                this.selectedIndex -= 1;
            }
        }
        if (e.key === "Enter") {
            const item = this.results[this.selectedIndex];

            this.goTo(item);
        }
    }

    goTo(item: any) {
        const [itemType, itemId] = item.id.split("::");

        if (!itemType || !itemId) {
            return;
        }

        if (itemType === "activity") {
            this.setOfficeId(item.office_id);

            this.$router.push({ name: "dossier-detail", params: { id: `${itemId}` } });
        }

        this.search = "";

        this.results = [];

        this.open = false;
    }

    escapeRegExp(str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
    }

    getResultWithHighlight(result: string) {
        const searchWords = this.search.trim().split(/\s+/);
        let highlightedResult = result;

        searchWords.forEach((word) => {
            const escapedString = this.escapeRegExp(word);
            const regex = new RegExp(`${escapedString}`, "gi");
            highlightedResult = highlightedResult.replace(regex, "<b>$&</b>");
        });

        return highlightedResult;
    }
}
